<template>
  <sm-editable-list
    :controllerName="controllerName"
    :breadcrumbs="breadcrumbs"
    :tableCaption="tableCaption"
    :tableHeaders="tableHeaders"
    showCreateButton
    showEditButton
    showDeleteButton
    @create="onCreate('AdsBasesCreate')"
    @edit="(id) => onEdit('AdsBasesEdit', id)"
  />
</template>

<script>
// mixins
import editableList from '@/mixins/editableList.js';
// components
import SmEditableList from '@/components/views/SmEditableList.vue';

export default {
  name: 'AdsBases',

  components: {
    SmEditableList,
  },

  mixins: [editableList],

  data() {
    return {
      controllerName: 'AdsBases',
      tableCaption: 'Базы АДС',
      breadcrumbs: [
        {
          text: 'Клиенты',
          route: { name: 'CustomersMain' },
        },
        {
          text: 'Базы АДС',
        },
      ],
      tableHeaders: [
        { text: 'База', alias: 'base', order: '' },
        {
          text: 'Клиент',
          alias: 'customerName',
          order: '',
        },
        { text: 'Имя пользователя', alias: 'userName', order: '' },
        { text: '', alias: 'actions', order: '' },
      ],
    };
  },
};
</script>
